export default {
  category: 'book',
  name: 'Start Love Repeat',
  author: 'Dorcas Cheng-Tozun',
  date: '8/29/24',
  path: '/notes/start-love-repeat',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-start-love-repeat-dorcas-cheng-tozun.jpg',
  tags: ['Business', 'Money', 'Entrepreneurship', 'Relationships'],
  time: 30,
  rating: 7.7,
  review: '',
  link: 'https://www.amazon.com/Start-Love-Repeat-Entrepreneur-Start-up/dp/1478920742',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/66c216470006b33fdf3bacab',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
