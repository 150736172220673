export default {
  category: 'book',
  name: 'The Generous Leader',
  author: 'Joe Davis',
  date: '9/6/24',
  path: '/notes/the-generous-leader',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-generous-leader-joe-davis.jpg',
  tags: ['Business', 'Money', 'Leadership'],
  time: 10,
  rating: 6.8,
  review: '',
  link: 'https://www.amazon.com/Generous-Leader-Ways-Yourself-Everyones/dp/1523006617',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/66c3eae4b69ad382012e6978',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
