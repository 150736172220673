export default {
  category: 'book',
  name: 'The Strangest Secret',
  author: 'Earl Nightingale',
  date: '9/12/24',
  path: '/notes/the-strangest-secret',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-strangest-secret-earl-nightingale.jpg',
  tags: ['Self-help'],
  time: 11,
  rating: 9.2,
  review: '',
  link: 'https://www.amazon.com/Strangest-Secret-Essence-Success/dp/9380494580',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/66e2e1e339e7bce89c551e9e',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
