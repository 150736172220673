export default {
  category: 'book',
  name: 'Secrets of Sand Hill Road',
  author: 'Scott Kupor',
  date: '9/8/24',
  path: '/notes/secrets-of-sand-hill-road',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-secrets-of-sand-hill-road-scott-kupor.jpg',
  tags: ['Business', 'Money', 'Finance', 'Venture capital', 'Fundraising', 'Entrepreneurship'],
  time: 39,
  rating: 5.8,
  review: '',
  link: 'https://www.amazon.com/Secrets-Sand-Hill-Road-Venture/dp/059308358X',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/66b8bf9446455d840ab832b0',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
